<footer class="footer">
	<div class="row">
		<!-- Contacts -->
		<div class="col col-12 col-md-4 col-start">
			<h5>Contactos</h5>
			<p>Suporte - Loja Online</p>
			<p class="mb-0"><a href="tel:+351220969940">+351 220 969 940</a></p>
			<p class="small-text">(chamada para rede fixa)</p>
			<p class="mb-0"><a href="tel:+351912156666">+351 912 156 666</a></p>
			<p class="small-text">(chamada para rede móvel)</p>
			<p><a href="mailto:shop@opticaboavista.com">shop@opticaboavista.com</a></p>
		</div>

		<!-- Newsletter -->
		<div class="col col-12 col-md-4">
			<h5>Newsletter</h5>
			<p>Subscreva a nossa Newsletter e receba em primeira mão todas as novidades</p>
			<!--<div class="eg-embed-2e5e6JpB"></div>-->
		</div>

		<!-- Social Links -->
		<div class="col col-12 col-md-4">
			<p>Siga-nos nas redes sociais:</p>
			<p>
				<app-social-links></app-social-links>
			</p>
			<p><a href="/info/condicoes-de-utilizacao" role="button">Condições de utilização</a></p>
			<p><a href="/info/politica-de-privacidade" role="button">Política de privacidade</a></p>
			<p *ngIf="merchant?.use_devolution"><a role="button" href="/info/politica-de-devolucoes">Política de devoluções</a></p>
			<p *ngIf="merchant?.use_cookies"><a role="button" href="/info/politica-de-cookies">Política de cookies</a></p>
			<p *ngIf="merchant?.ral_status"><a role="button" href="/info/ral">Resolução alternativa de litígios</a></p>
		</div>
	</div>
  <div class="row text-center">
      <a href="https://www.livroreclamacoes.pt/Pedido/Reclamacao" target="_blank">
        <img class="lrc" src="assets/images/lr-logo.png" alt="Livro de Reclamações">
      </a>
  </div>
</footer>
