<div class="container-search my-5">
	<form class="form" [formGroup]="searchForm">
		<input #input id="search" class="input px-2 text-center" type="text" formControlName="search" placeholder="Pesquisar" />
	</form>

	<p class="my-2 text-center">Experimente pesquisar "óculos de sol vogue"</p>

	<div role="button" (click)="dismiss()" class="btn-close col-2">
		<i class="fas fa-close fa-3x"></i>
	</div>
</div>

<div class="container">
  <div class="row" *ngIf="loading">
    <p class="text-center mt-5">
      <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" /></path>
      </svg>
    </p>
  </div>
	<div *ngIf="!loading" class="content-products">
		<app-container-products *ngIf="products.length > 0" (openProduct)="dismiss()" [seeAllProduct]="true" [products]="products"></app-container-products>
	</div>
</div>
