<div class="row main-loading" *ngIf="mainLoading">
  <p class="text-center">
    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" /></path>
    </svg>
  </p>
</div>
<div *ngIf="!mainLoading" class="img-category mt-3" [style.background-image]="'url(' + image + ')'">
<!--	<div class="overlay"></div>-->
<!--	<h2>{{ title }}</h2>-->
</div>

<div *ngIf="!mainLoading" class="container container-products mt-5">
	<div class="product-container mt-5 mb-5">
		<div class="row">
			<div class="col-12 col-lg-3">
				<div class="buttons-grid">
					<img src="assets/images/grid-4.svg" (click)="gridChange(3)" alt="" class="img-fluid" />
					<img src="assets/images/grid-2.svg" (click)="gridChange(6)" alt="" class="img-fluid" />
				</div>

        <span class="mt-5 w-50 btn-filter" (click)="showFilters = !showFilters">
          <i class="fa fa-filter"></i>
          Filtros
        </span>

        <div *ngIf="showFilters" class="form">
          <div *ngIf="categories.length">
            <h3 role="button" (click)="collapseCategories.toggle()">Categorias</h3>
            <div #collapseCategories="ngbCollapse" [(ngbCollapse)]="isCollapsedCategories">
              <p *ngFor="let category of categories"><input (change)="categoryChange($event)" type="checkbox" [checked]="category.checked" [id]="'category-' + category.id" [value]="category.id" /> {{ category.name }}</p>
            </div>
          </div>

          <div *ngIf="subcategories.length">
            <h3 role="button" (click)="collapseSubcategories.toggle()">Subcategorias</h3>
            <div #collapseSubcategories="ngbCollapse" [(ngbCollapse)]="isCollapsedSubcategories">
              <p *ngFor="let subcategory of subcategories"><input (change)="subcategoryChange($event)" type="checkbox" [checked]="subcategory.checked" [id]="'subcategory-' + subcategory.id" [value]="subcategory.id" /> {{ subcategory.name }}</p>
            </div>
          </div>

          <div *ngIf="brands.length">
            <h3 role="button" (click)="collapseBrands.toggle()">Marcas</h3>
            <div #collapseBrands="ngbCollapse" [(ngbCollapse)]="isCollapsedBrands">
              <p *ngFor="let brand of brands"><input (change)="brandChange($event)" type="checkbox" [checked]="brand.checked" [id]="'brand-' + brand.id" [value]="brand.id" /> {{ brand.name }}</p>
            </div>
          </div>

          <div>
            <h3 role="button" (click)="collapsePrices.toggle()">Preços</h3>
            <div #collapsePrices="ngbCollapse" [(ngbCollapse)]="isCollapsedPrices">
              <p class="align-items-center" *ngFor="let price of prices"><input (change)="priceChange($event)" type="checkbox" [id]="'price-' + price.id" [value]="price.id" /> {{ price.name }}</p>
            </div>
          </div>
        </div>
      </div>

			<div class="col-12 col-lg-9">
				<p *ngIf="!products.length && verifyProducts" class="text-center">Sem produtos!</p>
				<div class="row" *ngIf="products.length">
					<div *ngFor="let product of products" [class]="'col-12 col-sm-6 col-md-6 col-lg-' + col">
						<app-product-view [product]="product" [brand]="getBrandName(product.brand_id)"></app-product-view>
					</div>
				</div>

				<div class="row" *ngIf="loading">
					<p class="text-center mt-5">
						<svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" type="rotate" dur="0.6s" values="0 12 12;360 12 12" repeatCount="indefinite" /></path>
						</svg>
					</p>
				</div>
				<button *ngIf="page >= 0 && products.length && !loading" class="btn center btn-primary" (click)="seeMore()">Ver mais</button>
			</div>
		</div>
	</div>
</div>
