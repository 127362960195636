<div role="button" (click)="openProduct(product)" class="content-card align-items-bottom">
	<div class="image-content">
		<i *ngIf="user && !product.favorite" role="button" (click)="addFavorite($event)" class="fav-icon far fa-heart fa-lg"></i>
		<i *ngIf="user && product.favorite" role="button" (click)="addFavorite($event)" class="fav-icon fas fa-heart fa-lg"></i>
		<i *ngIf="product.sell_online && product.stock > 0" role="button" (click)="addProduct($event, product)" class="add-icon fas fa-plus fa-lg"></i>
		<div *ngIf="!product.image2" class="img-product center" [style.background-image]="'url(' + product.image + ')'"></div>
		<div *ngIf="product.image2" class="img-product center img-1" [style.background-image]="'url(' + product.image + ')'"></div>
		<div *ngIf="product.image2" class="img-product center img-2" [style.background-image]="'url(' + product.image2 + ')'"></div>
	</div>
	<h5 class="text-center">{{ brand }}</h5>
	<p class="text-center">{{ product.name }}</p>
	<p *ngIf="!product.discount" class="text-center">
		<b>{{ product.price && product.sell_online ? product.price + ' €' : '--' }}</b>
	</p>
	<p *ngIf="product.discount && product.sell_online" class="text-center">
		<span class="discount-price">{{ product.price }} €</span><b> {{ product.price - product.price * product.discount * 0.01 }} €</b>
	</p>
	<p *ngIf="product.discount  && product.sell_online" class="m-0 text-center">
		<span class="box-dark"> -{{ product.discount }}% </span>
		<span class="box-ligth">Promoções</span>
	</p>
	<br />
</div>
