<div class="container container-html">
  <div class="row">
    <div class="col-lg-12 col-12">
      <h1 class="text-lg-start text-center">{{ title }}</h1>
      <div class="text" [innerHtml]="data"></div>
      <div *ngIf="isCookies">
        <hr/>
        <p><i class="fa fa-info-circle fa-lg"></i> Pode gerir ou desativar alguns destes cookies usando o botão “Configurar Cookies”.</p>
        <button class="cm__btn" type="button" (click)="openModal()">
          Configurar Cookies
        </button>
      </div>
    </div>
  </div>
</div>
