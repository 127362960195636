import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {OrderService, PaymentService} from '@services/services.service';
import { BasicComponent } from '@pages/basic/basic.component';
import {Merchant, Order, User} from '@models/models.model';
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss']
})
export class OrderComponent extends BasicComponent implements OnInit {
  @ViewChild('paymentForm') paymentForm: ElementRef;
  @ViewChild('sibsModal', { static: true }) sibsModal: ElementRef;

	user: User;
  order: Order;
  merchant: Merchant;
	orderId: number;
  loading: boolean = false;
  isSpg: boolean = false;

	constructor(private router: Router, private orderService: OrderService, private location: Location, private activatedRoute: ActivatedRoute, private paymentService: PaymentService) {
		super();

		this.user = this.storageService.get('user');
    this.merchant = this.storageService.get('merchant');

    if (!this.merchant || !this.user) {
      this.navigateHome();
    }
    this.isSpg = this.merchant.payments.cc == 'sibs-spg';

		if (!this.user) {
			this.navigateHome();
		}

		this.activatedRoute.params.subscribe(async (params: any) => {
			this.orderId = params.id;
			this.load();
		});
	}

	ngOnInit(): void {}

	load() {
		this.orderService.one(this.orderId).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.order = resp.data;
        setTimeout(() => {
          this.checkStatus();
        }, 2000);
			}
		});
	}

	goBack() {
		this.location.back();
	}

  checkStatus(loading = false) {
    if (loading) {
      this.loading = true;
    }
    this.paymentService.status(this.orderId).subscribe((resp: any) => {
      if (resp.status == 'OK') {
        if (resp.data.status !== 'pending') {
          this.load();
        } else {
          if (this.order.status === 'Submetida' && this.order.payment_type !== 'MASTER VISA') {
            setTimeout(() => {
              this.checkStatus();
            }, 10000);
          }
        }
      } else {
        this.toastService.showError(resp.data.message);
      }
      this.loading = false;
    });
  }

  openPay() {
    this.router.navigate(['/pagar', this.order.id]);
  }
}
