export const environment = {
	production: false,
	apiUrl: 'https://opticae.online/api/',
	merchantId: 35,
	merchantUrl: 'opticaboavista.opticae.online',
	facebookApiKey: '',
	stripeKey: '',
	googleApiKey: '',
	url_base: 'https://opticaboavista.com/'
};
