import { Component, OnInit } from '@angular/core';
import { Product } from '@models/models.model';
import { ProductService } from '@services/services.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BasicComponent } from '@pages/basic/basic.component';
import { debounceTime } from "rxjs/operators";

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent extends BasicComponent implements OnInit {
	searchForm: FormGroup;
	products: Product[] = [];
	term: string = '';
  loading = false;
	constructor(private ngbActiveModal: NgbActiveModal, private productService: ProductService, private formBuilder: FormBuilder) {
		super();

		this.searchForm = this.formBuilder.group({
			search: [null, [Validators.required]]
		});

    const searchControl = this.searchForm.get("search");
    // @ts-ignore
    searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.search(value);
    });
	}

	ngOnInit(): void {
	}

	search(event: any) {
		if (event.length > 2) {
			this.term = event;
			this.loadProducts();
		}
	}

	loadProducts() {
    this.loading = true;
		this.productService.all(this.term).subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.products = resp.data;
        this.loading = false;
			}
		});
	}

	dismiss() {
		this.ngbActiveModal.close();
	}
}
