interface About {
	id: string;
	name: string;
	resume: string;
	description: string;
	image: string;
	see?: boolean;
}

const ABOUTS: About[] = [
	{
		id: 'parque-de-estacionamento',
		name: 'PARQUE DE ESTACIONAMENTO',
		resume: 'A chegada à Óptica Boavista de carro é feita pela Rua Guerra Junqueiro 640. Ao chegar, será recebido por um colaborador do estabelecimento que o orientará no parque de estacionamento. O parque de estacionamento possui uma capacidade para acomodar até 30 viaturas, oferecendo amplo espaço para os clientes estacionarem com facilidade.',
		description:
      '<p>A chegada à Óptica Boavista de carro é feita pela Rua Guerra Junqueiro 640. Será recebido por um colaborador do estabelecimento que o vai orientar no parque de estacionamento. O parque de estacionamento possui uma capacidade para acomodar até 30 viaturas, oferecendo um espaço amplo para os clientes estacionarem com facilidade.</p>' +
      '<p>Ao estacionar o veículo, notará a presença de uma cadeira de rodas em frente ao elevador. Essa cadeira de rodas está disponível para uso de pessoas que necessitem de auxílio para se locomover. É um recurso útil e inclusivo que demonstra a preocupação da Óptica Boavista com a acessibilidade.</p>' +
      '<p>Ao entrar no elevador, será transportado diretamente ao piso da loja. O elevador é projetado para atender às necessidades de mobilidade dos clientes, proporcionando uma transição suave entre o estacionamento e o interior da óptica.</p>' +
      '<p>Assim que o elevador chega ao piso da loja, será deixado exatamente na intersecção entre a Clínica e a área de aconselhamento e venda de óculos. Essa localização estratégica permite um acesso conveniente aos diferentes setores da óptica, proporcionando uma experiência eficiente e bem organizada para os clientes.</p>',
    image: 'assets/images/about/about-1.jpg'
	},
	{
		id: 'clinica',
		name: 'CLÍNICA',
		resume: 'A Clínica da Óptica Boavista é um estabelecimento moderno e bem equipado, com uma equipe altamente qualificada, serviços abrangentes e produtos de qualidade. Destaca-se pelo atendimento personalizado, excelência clínica e compromisso com a saúde ocular de seus pacientes.',
		description:
			'<p>A Clínica da Óptica Boavista é um estabelecimento moderno e bem equipado, com uma equipa altamente qualicada, serviços abrangentes e produtos de qualidade. </p>' +
			'<p>Destaca-se pelo atendimento personalizado, excelência clínica e compromisso com a saúde ocular dos seus pacientes.</p>' +
			'<p>Oferece uma ampla gama de valências multi-disciplinares para atender às diversas necessidades de saúde ocular e bem-estar dos pacientes. </p>' +
			'<p>Essas valências incluem: consultas de Optometria,Contactologia, Psicologia, Audiologia, Fisioterapia, Terapia visual, Optometria comportamental e Ortóptica.</p>' +
			'<p>Além destas valências, a clínica também oferece uma ampla gama de exames de diagnósticos e tecnologias avançadas, como: • Tensão Ocular (Tonometria): Medição da pressão intraocular para detectar possíveis sinais de glaucoma. Retinograa: Fotograa digital do fundo do olho para avaliar a saúde da retina. • Perimetria Estática Computorizada (Campimetria): Teste para avaliar o campo visual e identicar áreas de perda de visão. • Tomograa de Coerência Óptica: Exame não invasivo que permite visualizar as camadas da retina, útil para diagnóstico de doenças oculares. • Topograa Corneana: Mapeamento da superfície da córnea para avaliar a curvatura e detectar irregularidades. • Microscopia Especular: Avaliação das células endoteliais da córnea para monitorar a saúde corneana. • Estudo da Lágrima: Análise das 2 características e qualidade das lágrimas para diagnóstico e tratamento de olho seco.</p>' +
			'<p>A Clínica da Óptica Boavista orgulha-se de utilizar as mais avançadas tecnologias para fornecer um diagnóstico preciso e um atendimento de qualidade aos seus pacientes.</p>' +
			'<p>Para agendar uma consulta ou exame, os pacientes podem utilizar a área "Marcar Consulta" neste site.</p>',
		image: 'assets/images/about/about-2.jpg'
	},
	{
    id: 'oficina',
    name: 'OFICINA',
    resume: 'O nosso laboratório é um centro de excelência em óptica, dedicado a fornecer soluções de alta qualidade para todos os tipos de óculos e lentes. Aqui, contamos com uma equipa de especialistas altamente treinados e experientes, apaixonados pelo que fazem e comprometidos em oferecer o melhor atendimento ao cliente.',
    description:
      '<p>O nosso laboratório é um centro de excelência em óptica, dedicado a fornecer soluções de alta qualidade para todos os tipos de óculos e lentes. Aqui, contamos com uma equipa de especialistas altamente treinados e experientes, apaixonados pelo que fazem e comprometidos em oferecer o melhor atendimento ao cliente.</p>' +
      '<p>Principais Características do Nosso Laboratório:</p>' +
      '<ol>' +
      '<li>Reparação a Laser: Somos pioneiros em Portugal ao oferecer reparação a laser para óculos. Essa tecnologia de ponta permite-nos efetuar reparações precisas e duradouras, sem danificar a estrutura ou os materiais dos óculos. Devido à precisão do laser, conseguimos trabalhar com peças delicadas, como os óculos feitos em titânio.</li>' +
      '<li>Tecnologia de Última Geração: Investimos em maquinaria de ponta para garantir resultados excecionais em todos os serviços que oferecemos. Os nossos equipamentos são escolhidos para estar em conformidade com os mais altos padrões da indústria, o que nos permite lidar com os mais variados tipos de óculos, lentes e materiais.</li>' +
      '<li>Especialização em Soldas de Titânio: Nossos técnicos são especialistas na realização de soldas em titânio, o que é uma habilidade rara e valiosa no setor óptico. O titânio é um material nobre e muito utilizado no fabrico de óculos devido à sua leveza e resistência, mas a soldagem convencional não é possível. Graças à nossa experiência e equipamento laser, podemos lidar com essas situações com facilidade e precisão.</li>' +
      '<li>Eficiência no Serviço: oferecemos um serviço rápido e eficiente, entregando os óculos completos em apenas 30 minutos para lentes padrão e em 3 horas para lentes progressivas.</li>' +
      '<li>Especialização em Diversos Tipos de Lentes: a nossa equipa é altamente qualificada e possui uma vasta experiência na montagem de todos os tipos de lentes disponíveis no mercado. Desde lentes monofocais, regressivas, com diferentes tratamentos para uma melhor saúde visual e conforto até lentes progressivas de alta tecnologia, temos o conhecimento necessário para proporcionar uma experiência visual excecional aos nossos clientes.</li>' +
      '<li>Marcação Precisa: Utilizamos equipamentos de tecnologia avançada para marcação das lentes de acordo com as especificações únicas de cada usuário. Essa marcação precisa é essencial para garantir que os óculos sejam personalizados de acordo com as necessidades visuais individuais, assegurando o máximo conforto e eficácia na correção visual.</li>' +
      '<li>Adaptação Personalizada: Reconhecemos que cada cliente é único, e as suas necessidades visuais podem variar. Portanto, personalizamos cada par de óculos de acordo com as preferências e estilo de vida do usuário. A nossa equipa trabalha em estreita colaboração para garantir que os óculos atendam às expectativas do cliente.</li>' +
      '<li>Uso de Tecnologia de Ponta: Investimos continuamente em tecnologia de ponta para melhorar os nossos serviços e garantir que os nossos clientes tenham acesso às mais recentes inovações no campo da óptica. Isso permite-nos oferecer resultados de excelência em todas as etapas do processo, desde a montagem até a entrega dos óculos finalizados.</li>' +
      '<li>Controlo Minucioso de Qualidade: Temos como compromisso o atendimento ágil e eficiente onde cada par de óculos é minuciosamente verificado e testado com um apertado controlo de qualidade para garantir que atenda aos mais altos padrões de qualidade e precisão óptica.</li>' +
      '<li>Garantia de Satisfação: Estamos confiantes na qualidade do nosso trabalho e nos materiais que usamos. Por isso, oferecemos garantia de satisfação em todos os nossos serviços. </li>' +
      '</ol>' +
      '<p>Ao escolher a Óptica Boavista Megastore, os nossos clientes têm a certeza de que os seus óculos serão tratados com o máximo de cuidado e expertise, utilizando tecnologia de vanguarda para garantir a excelência em cada trabalho realizado.</p>' +
      '<p>Venha visitar-nos ou entre em contacto para saber mais sobre os nossos serviços. Estamos ansiosos para o atender e lhe proporcionar a melhor experiência em cuidados ópticos que você já teve!</p>',
		image: 'assets/images/about/about-3.jpg'
	},
	{
    id: 'atendimento-personalizado-oculos-graduados-e-sol',
    name: 'ATENDIMENTO PERSONALIZADO<br/>ÓCULOS GRADUADOS E SOL',
    resume: 'Na Óptica Boavista Megastore, estamos empenhados em lhe oferecer um atendimento personalizado e exclusivo que se destaca em todos os sentidos. Com mais de 50 marcas de armações e óculos de sol, incluindo as tendências mais recentes, abraçamos a diversidade para atender a todos os gostos e necessidades.',
		description:
    '<p>Na Óptica Boavista Megastore, estamos empenhados em lhe oferecer um atendimento personalizado e exclusivo que se destaca em todos os sentidos. Com mais de 50 marcas de armações e óculos de sol, incluindo as tendências mais recentes, abraçamos a diversidade para atender a todos os gostos e necessidades, garantindo que cada cliente encontre o par perfeito que reflita o seu estilo único.</p>' +
    '<p>O nosso foco está no luxo, no entanto, também temos uma grande variedade de preços para satisfazer diferentes orçamentos. Dos 50€ aos deslumbrantes 30000€, nossos óculos variam em materiais, desde os mais simples e elegantes até os luxuosos feitos em ouro, platina e diamantes. Aqui na Óptica Boavista Megastore, acreditamos que a moda e a qualidade devem estar ao alcance de todos, independentemente do seu estilo de vida.</p>' +
    '<p>Quando se trata de lentes, nunca comprometemos a excelência. Utilizamos apenas as marcas internacionais mais prestigiadas, garantindo a melhor qualidade e o máximo conforto. Estamos dedicados a cuidar da saúde visual de nossos clientes, proporcionando uma visão clara e nítida, além de garantir o conforto durante todo o dia.</p>' +
    '<p>A nossa equipa de especialistas altamente treinados e experientes está pronta para acompanhá-lo na sua jornada para encontrar o seu par de óculos perfeito. Esforçamo-nos para entender as suas preferências pessoais, estilo e necessidades específicas para oferecer recomendações personalizadas que se alinhem perfeitamente com o seu perfil.</p>' +
    '<p>Além disso, o nosso serviço pós-venda é exemplar, garantindo que a sua experiência com a Óptica Boavista Megastore seja memorável desde o momento da escolha até muito além do momento da compra. A nossa missão é construir relacionamentos de longo prazo com os nossos clientes, garantindo que eles sempre recebam a atenção e o cuidado que merecem.</p>' +
    '<p>Venha visitar-nos e experimentar a verdadeira excelência em atendimento personalizado. Na Óptica Boavista Megastore, estamos prontos para transformar o modo como vê o mundo - com estilo, luxo e qualidade indiscutível.</p>' +
    '<p>Experimente a diferença na Óptica Boavista Megastore – onde os seus olhos e o seu estilo se encontram!</p>',
		image: 'assets/images/about/about-4.jpg'
	},
  {
    id: 'atendimento-personalizado-infantil',
    name: 'ATENDIMENTO PERSONALIZADO INFANTIL',
    resume: 'Na Óptica Boavista Megastore, acreditamos que os olhos das crianças merecem cuidados especiais e atenção dedicada. Por isso, temos um espaço especialmente projetado para os mais pequenos, onde oferecemos uma extensa variedade de óculos desde os mais simples até aos mais irreverentes e divertidos.',
    description:
      '<p>Na Óptica Boavista Megastore, acreditamos que os olhos das crianças merecem cuidados especiais e atenção dedicada. Por isso, temos um espaço especialmente projetado para os mais pequenos, onde oferecemos uma extensa variedade de óculos desde os mais simples até aos mais irreverentes e divertidos, não esquecendo os indicados para os mais diversos desportos (óculos de natação, futebol e muitos outros, todos graduáveis).</p>' +
      '<p>Sempre apresentamos uma coleção encantadora de óculos, especialmente pensados para crianças de todas as idades. Desde os primeiros óculos para bebés (desde os 0 meses) até aos modelos mais modernos para adolescentes, a nossa seleção combina estilo, conforto e qualidade para atender aos diferentes estágios de crescimento e às preferências individuais.</p>' +
      '<p>Entendemos que cada criança é única e tem sua personalidade e gostos específicos, por isso, oferecemos uma ampla variedade de cores, formatos e personagens favoritos para que elas se sintam especiais e animadas com os seus novos óculos.</p>' +
      '<p>Além disso, sabemos o quanto é importante garantir a proteção e saúde visual das crianças em fase de crescimento. Portanto, escolhemos cuidadosamente as lentes e materiais que oferecem a melhor qualidade óptica, resistência e conforto para as suas atividades diárias. Tendo sempre no nosso portfolio as marcas de lentes e de armações mais aconselhadas pelos Oftalmologistas Pediátricos.</p>' +
      '<p>Venha visitar a nossa loja e descubra a coleção exclusiva de óculos infantis, onde combinamos moda e função para cuidar do estilo e saúde visual dos mais pequenos. Aqui, na Óptica Boavista Megastore, estamos empenhados em tornar a jornada visual das crianças tão empolgante e única quanto elas são!</p>',
    image: 'assets/images/about/about-5.jpg'
  },
  {
    id: 'boutique-boavista',
    name: 'BOUTIQUE BOAVISTA',
    resume: 'Na Boutique Boavista, proporcionamos uma experiência de compras excecional, onde qualidade, estilo e atendimento personalizado se unem. Localizada dentro da Óptica Boavista, a nossa boutique é um espaço encantador dedicado a roupas e acessórios exclusivos.',
    description:
      '<p>Na Boutique Boavista, proporcionamos uma experi&ecirc;ncia de compras excecional, onde qualidade, estilo e atendimento personalizado se unem. Localizada dentro da &Oacute;ptica Boavista, a nossa boutique &eacute; um espa&ccedil;o encantador dedicado a roupas e acess&oacute;rios exclusivos.</p><p>Na nossa boutique, temos uma equipa de consultores de moda que est&aacute; sempre pronta para oferecer um atendimento personalizado e ajud&aacute;-la a encontrar as roupas e acess&oacute;rios perfeitos para qualquer ocasi&atilde;o. Com o seu conhecimento especializado e senso de moda, eles podem auxiliar na combina&ccedil;&atilde;o de pe&ccedil;as, oferecer conselhos sobre estilos e propor solu&ccedil;&otilde;es para atender &agrave;s suas necessidades individuais.</p><p>Al&eacute;m disso, a localiza&ccedil;&atilde;o privilegiada dentro da &Oacute;ptica Boavista permite que voc&ecirc; aproveite ao m&aacute;ximo a sua visita, combinando o prazer de escolher &oacute;culos de sol ou graduados com a oportunidade de renovar seu guarda-roupa. &Eacute; a combina&ccedil;&atilde;o perfeita para quem procura estilo e conforto visual num s&oacute; lugar.</p><p>Visite a nossa boutique e permita-nos surpreend&ecirc;-lo com uma sele&ccedil;&atilde;o &uacute;nica de pe&ccedil;as elegantes e de alta qualidade. Esperamos receb&ecirc;-lo em breve para uma experi&ecirc;ncia de compras inesquec&iacute;vel na &Oacute;ptica Boavista!</p>',
    image: 'assets/images/about/about-6.jpg'
  },
  {
    id: 'obv-cafe',
    name: 'OBV CAFÉ',
    resume: 'No OBVcafé desfrute do seu café deliciosamente encorpado e aromático e absorva a atmosfera tranquila e relaxante que criamos. É o local perfeito para apreciar um momento de calma e serenidade, seja sozinho, numa reunião ou enquanto aguarda os seus óculos ou a sua consulta. Além do café excepcional, o nosso menu apresenta também uma variedade de opções de pequeno almoço e lanches.',
    description:
      '<p>No OBVcafé desfrute do seu café deliciosamente encorpado e aromático e absorva a atmosfera tranquila e relaxante que criamos. É o local perfeito para apreciar um momento de calma e serenidade, seja sozinho, numa reunião ou enquanto aguarda os seus óculos ou a sua consulta. Além do café excepcional, o nosso menu apresenta também uma variedade de opções de pequeno almoço e lanches.</p>' +
      '<p>O nosso espaço é agraciado com claraboias no teto, permitindo a entrada de luz natural que cria uma atmosfera aconchegante e convidativa. A luz suave ilumina o ambiente, destacando detalhes encantadores e criando uma sensação de bem-estar.</p>' +
      '<p>Venha-nos visitar e descubra a magia de saborear um café delicioso num ambiente único e cheio de personalidade.</p>' +
      '<p>Na nossa boutique, temos uma equipa de consultores de moda que está sempre pronta para oferecer um atendimento personalizado e ajudá-la a encontrar as roupas e acessórios perfeitos para qualquer ocasião. Com o seu conhecimento especializado e senso de moda, eles podem auxiliar na combinação de peças, oferecer conselhos sobre estilos e propor soluções para atender às suas necessidades individuais.</p>' +
      '<p>Além disso, a localização privilegiada dentro da Óptica Boavista permite que você aproveite ao máximo a sua visita, combinando o prazer de escolher óculos de sol ou graduados com a oportunidade de renovar seu guarda-roupa. É a combinação perfeita para quem procura estilo e conforto visual num só lugar.</p>' +
      '<p>Visite a nossa boutique e permita-nos surpreendê-lo com uma seleção única de peças elegantes e de alta qualidade. Esperamos recebê-lo em breve para uma experiência de compras inesquecível na Óptica Boavista!</p>',
    image: 'assets/images/about/about-7.jpg'
  },
  {
    id: 'transforme-a-sua-iris',
    name: 'TRANSFORME A SUA ÍRIS',
    resume: 'Bem-vindo à Irisphoto.art, onde transformamos a janela da sua alma numa obra de arte deslumbrante. A nossa especialidade é a fotografia da íris, capturando cada detalhe único dos seus olhos em alta definição. Cada íris é como uma impressão digital, exclusiva e cheia de nuances que revelam a sua individualidade',
    description:
      '<p>Bem-vindo à Irisphoto.art, onde transformamos a janela da sua alma numa obra de arte deslumbrante.</p>' +
      '<p>A nossa especialidade é a fotografia da íris, capturando cada detalhe único dos seus olhos em alta definição. Cada íris é como uma impressão digital, exclusiva e cheia de nuances que revelam a sua individualidade.</p>' +
      '<p><b>Porque Fotografar a sua Íris?</b></p>' +
      '<ul>' +
      '<li><b>Exclusividade:</b> Não há duas íris iguais no mundo. Tenha uma peça de arte que é tão única quanto você.</li>' +
      '<li><b>Detalhe Incrível:</b> Os nossos equipamentos são os mais recentes e evoluídos do mercado, e por isso, capturam cada minúcia da sua íris com uma precisão impressionante.</li>' +
      '<li><b>Presentes Memoráveis:</b> Ofereça um presente inesquecível para alguém especial. Um retrato da íris é uma escolha singular e com significado profundo.</li>' +
      '<li><b>Decoração Elegante:</b> Adicione um toque de sofisticação e personalização à sua casa ou escritório com uma obra de arte que reflete sua essência.</li>' +
      '</ul>' +
      '<p><b>Como Funciona?</b></p>' +
      '<ol>' +
      '<li><b>Agendamento:</b> Marque a sua sessão no nosso estúdio através do nosso website ou por mensagem direta nas nossas redes sociais.</li>' +
      '<li><b>Captura da Íris:</b> A nossa equipa de fotógrafos especializados fará a captura da sua íris, utilizando técnicas avançadas para garantir imagens de alta qualidade.</li>' +
      '<li><b>Edição e Impressão:</b> Selecionamos e editamos as melhores imagens, oferecendo várias opções de impressão e molduras para que escolha a que mais lhe agrada.</li>' +
      '<li><b>Entrega:</b> Receba sua obra de arte em casa, pronta para ser exibida com orgulho.</li>' +
      '</ol>' +
      '<p>Estamos localizados na Avenida Boavista, nº1003. Para mais informações ou para agendar sua sessão, entre em contacto connosco através do número de telemóvel +351 912 156 666 ou através das nossas redes sociais.</p>' +
      '<p>Siga-nos em <a href="https://www.instagram.com/irisphoto.art_portoboavista" target="_blank">@irisphoto.art_portoboavista</a> para ver mais exemplos do nosso trabalho e ficar por dentro das nossas novidades .</p>',
    image: 'assets/images/about/about-8.jpg'
	}
];

const DESCRIPTION: string =
  'Um espaço multidisciplinar, com áreas distintas, que oferece serviços tão inovadores como, por exemplo, conseguir que as lentes dos seus óculos estejam prontas em apenas 1 hora! Aqui, vai encontrar mais de 50 marcas de armações, óculos de sol e óculos de desporto das melhores griffes do mundo. Para maior conforto e satisfação dos seus clientes poderá  ainda tomar o pequeno almoço ou lanchar - no OBV Café – e estacionar o carro no parque privativo com mais de 30 lugares e acesso directo à loja. A Óptica Boavista oferece também serviços especializados para crianças (fraldário, atendimento personalizado específico e sala de espera para os mais pequenos).'
export { About, ABOUTS, DESCRIPTION };
