<h2 *ngIf="title" class="ob-title">{{ title }}</h2>

<div class="products">
	<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
		<div ngxSlickItem *ngFor="let product of products" class="slide">
			<app-product-view (onOpenProduct)="onOpenProduct()" [product]="product" [brand]="getBrandName(product.brand_id)"></app-product-view>
		</div>
	</ngx-slick-carousel>

	<ng-container *ngIf="slickModal">
		<button class="btn-arrow arrow-left" (click)="slickModal.slickPrev()"><i class="fas fa-angle-left fa-2x"></i></button>
		<button class="btn-arrow arrow-right" (click)="slickModal.slickNext()"><i class="fas fa-angle-right fa-2x"></i></button>
	</ng-container>
</div>
<button (click)="openProducts()" *ngIf="seeAllProduct" class="btn btn-primary center">Ver Todos</button>
<div class="mb-5" *ngIf="!seeAllProduct">
	<br />
</div>
