<div *ngIf="about">
	<div class="img-about mt-3" [style.background-image]="'url(' + about.image + ')'"></div>

	<h2 class="ob-title" [innerHtml]="about.name"></h2>

	<div class="about-description" [innerHtml]="about.description"></div>

	<!-- Abouts -->
	<div *ngIf="abouts.length" class="abouts-container">
		<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
			<div ngxSlickItem *ngFor="let a of abouts" [ngClass]="{ see: !a.see }" class="slide">
				<a [href]="'/quem-somos/' + a.id" *ngIf="a.see" class="card">
					<img [src]="a.image" class="card-img-top" />
					<div class="card-body">
						<h5 class="card-title" [innerHtml]="a.name"></h5>
					</div>
				</a>
			</div>
		</ngx-slick-carousel>
	</div>
</div>
