import { Component, Input, OnInit } from '@angular/core';
import { LoginComponent } from '@pages/login/login.component';
import { SearchComponent } from '@pages/search/search.component';
import { Cart, User } from '@models/models.model';
import { BasicComponent } from '@pages/basic/basic.component';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BrandService, CategoryService, SpecialtyService, UserService } from '@services/services.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends BasicComponent implements OnInit {
	activeRoute: string;
	categories: any[] = [];
	user: User;
	cart: Cart;
	collapsed: boolean = true;
	showCart: boolean;
	disableHover: boolean = false;
  selectedBrand: any = null;
	selectedCategory: any = null;
	selectedSubCategory: any = null;
	brands: any[][] = [[], [], [], []];
	specialties: any[] = [];
  cartCount = 0;

	constructor(public router: Router, private userService: UserService, private location: Location, private categoryService: CategoryService, private brandService: BrandService, private specialtyService: SpecialtyService) {
		super();

		router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				// Show loading indicator
				this.collapsed = true;
			}

			if (event instanceof NavigationEnd) {
				// Hide loading indicator
				this.activeRoute = this.router.url;
			}

			if (event instanceof NavigationError) {
				this.navigateHome();
			}
		});

		this.user = this.storageService.get('user');
		this.cart = this.storageService.get('cart');

    if(this.cart) {
      this.cartCount = this.cart.quant;
    }

		this.eventsService.subscribe('update:cart', (cart) => {
			this.cart = cart;
      this.cartCount = this.cart.quant;
			this.openCloseCart();
		});
	}

	ngOnInit(): void {
		this.router.events.subscribe((event: any) => {
			this.disableHover = true;
			if (!(event instanceof NavigationEnd)) {
				setTimeout(() => {
					this.disableHover = false;
				}, 1000);
				return;
			}
		});

		this.loadCategories();
		this.loadBrands();
		this.loadSpecialties();
	}

	loadCategories() {
		this.categoryService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.categories = this.spliceInto(resp.data, resp.data.length);
				this.categories.forEach((category: any) => {
					category.forEach((cat: any) => {
						this.categoryService.subcategories(cat.id).subscribe((data: any) => {
							cat.subcategories = data.data;
						});
					});
				});
			}
		});
	}

	loadBrands() {
		this.brandService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				let aux = resp.data.splice(0, 28);
				this.brands = this.spliceInto(aux, 4);
			}
		});
	}

	loadSpecialties() {
		this.specialtyService.all().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.specialties = resp.data;
			}
		});
	}

	toggleCollapsed() {
		this.collapsed = !this.collapsed;
	}

	openModal(page: string) {
		let component = null;
		let css: string = 'modal-container';
		switch (page) {
			case 'login':
				component = LoginComponent;
				break;
			default:
				component = SearchComponent;
				break;
		}

		this.modalsService.openModal(component, true, css);
	}

	logout() {
		this.userService.logout().subscribe((resp: any) => {
			if (resp.status == 'OK') {
				this.storageService.remove('user');
				this.navigateHome();
			}
		});
	}

	toggleCart() {
		this.showCart = !this.showCart;
	}

	openCart() {
		this.showCart = true;
	}

	closeCart() {
		this.showCart = false;
	}

	openCloseCart() {
		this.showCart = true;
		setTimeout(() => {
			this.showCart = false;
		}, 5000);
	}

	spliceInto(items: any, n: number) {
		let res: any[][] = Array.from(Array(n), () => []);
		const wordsPerLine = Math.ceil(items.length / n);

		for (let line = 0; line < n; line++) {
			for (let i = 0; i < wordsPerLine; i++) {
				const value = items[i + line * wordsPerLine];
				if (!value) continue;
				res[line].push(value);
			}
		}

		return res;
	}
}
