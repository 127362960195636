import { Component, Injector, Type } from '@angular/core';
import { ServiceLocator } from '@app/app.module';
import { EventsService, ModalsService, StorageService, ToastService } from '@services/services.service';
import * as CookieConsent from "vanilla-cookieconsent";

@Component({
	selector: 'app-basic',
	template: ` <p>base works!</p> `,
	styleUrls: []
})
export class BasicComponent {
	protected eventsService: EventsService;
	protected storageService: StorageService;
	protected modalsService: ModalsService;
	protected toastService: ToastService;

	constructor() {
		const injector: Injector = ServiceLocator.injector;
    CookieConsent.run({
      onFirstConsent: ({ cookie }) => {
      },

      onConsent: ({ cookie }) => {
      },

      onChange: ({ changedCategories, changedServices }) => {
      },

      onModalReady: ({ modalName }) => {
      },

      onModalShow: ({ modalName }) => {
      },

      onModalHide: ({ modalName }) => {
      },

      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true // this category cannot be disabled
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/ // regex: match all cookies starting with '_ga'
              },
              {
                name: "_gid" // string: exact cookie name
              }
            ]
          },
          services: {
            ga: {
              label: "Google Analytics",
              onAccept: () => {
              },
              onReject: () => {
              }
            }
          }
        },
        ads: {
          autoClear: {
            cookies: [
              {
                name: "_fbp" // string: exact cookie name
              }
            ]
          },
          services: {
            fbp: {
              label: "Facebook Pixel",
              onAccept: () => {
              },
              onReject: () => {
              }
            }
          }
        }
      },

      language: {
        default: "pt",
        translations: {
          pt: {
            consentModal: {
              title: "Usamos cookies",
              description: "Os cookies neste site são utilizados para melhorar a navegação e desempenho. Por defeito, só os cookies estritamente necessários se encontram ativos. Poderá aceitar ou rejeitar todos os cookies nos botões apresentados ou gerir ou desativar alguns destes cookies, clicando em “Configurar Cookies”. Para mais informação visite a nossa Política de Cookies.",
              acceptAllBtn: "Aceitar todos",
              acceptNecessaryBtn: "Rejeitar todos",
              showPreferencesBtn: "Configurar Cookies",
              // closeIconLabel: 'Reject all and close modal',
              footer: `
                    <a href="/info/politica-de-cookies" target="_blank">Política de Cookies</a>
                    <a href="/info/politica-de-privacidade" target="_blank">Política de Privacidade</a>
                `
            },
            preferencesModal: {
              title: "Preferências de cookies",
              acceptAllBtn: "Aceitar todos",
              acceptNecessaryBtn: "Rejeitar todos",
              savePreferencesBtn: "Aceitar a seleção atual",
              closeIconLabel: "Fechar",
              serviceCounterLabel: "Serviço|Serviços",
              sections: [
                {
                  title: "",
                  description: "Neste painel poderá manifestar algumas preferências relacionadas com o tratamento dos seus dados pessoais. Pode rever e alterar as escolhas expressas a qualquer momento, reaparecendo este painel através do link fornecido. Para negar o seu consentimento para as atividades de processamento específicas descritas abaixo, desative os botões ou utilize o botão “Rejeitar tudo” e confirme que pretende guardar as suas escolhas."
                },
                {
                  title: "Estritamente Necessários",
                  description: "Estes cookies são essenciais para o bom funcionamento do site e não podem ser desativados.",
                  linkedCategory: "necessary"
                },
                {
                  title: "Performance & Analytics",
                  description: "Estes cookies recolhem informações sobre como utiliza o nosso site. Todos os dados são anonimizados e não podem ser utilizados para o identificar.",
                  linkedCategory: "analytics",
                  cookieTable: {
                    caption: "Cookies Usados",
                    headers: {
                      name: "Cookie",
                      domain: "Domínio",
                      desc: "Descrição"
                    },
                    body: [
                      {
                        name: "_ga",
                        domain: window.location.hostname,
                        desc: "É o principal cookie utilizado pelo Google Analytics, permite ao serviço distinguir um visitante de outro e tem uma duração de 2 anos."
                      },
                      {
                        name: "_gid",
                        domain: window.location.hostname,
                        desc: "Regista um ID único que é utilizado para gerar dados estatísticos sobre a forma como o visitante utiliza o website. Este cookie expira após 1 dia."
                      },
                      {
                        name: "_fbp",
                        domain: window.location.hostname,
                        desc: "Este cookie é definido pelo Facebook para exibir anúncios quando está no Facebook ou numa plataforma digital alimentada por publicidade do Facebook, após visitar o website."
                      }
                    ]
                  }
                },
                {
                  title: "Targeting and Advertising",
                  description: "Estes cookies são utilizados para tornar as mensagens publicitárias mais relevantes para si e para os seus interesses.",
                  linkedCategory: "ads",
                  cookieTable: {
                    caption: "Cookies Usados",
                    headers: {
                      name: "Cookie",
                      domain: "Domínio",
                      desc: "Descrição"
                    },
                    body: [
                      {
                        name: "_fbp",
                        domain: window.location.hostname,
                        desc: "Este cookie é definido pelo Facebook para exibir anúncios quando está no Facebook ou numa plataforma digital alimentada por publicidade do Facebook, após visitar o website."
                      }
                    ]
                  }
                },
                {
                  title: "Mais informações",
                  description:
                    "Para qualquer dúvida em relação à nossa política de cookies e as suas escolhas, por favor contacte-nos."
                }
              ]
            }
          }
        }
      }
    });
		this.eventsService = injector.get<EventsService>(EventsService as Type<EventsService>);
		this.storageService = injector.get<StorageService>(StorageService as Type<StorageService>);
		this.modalsService = injector.get<ModalsService>(ModalsService as Type<ModalsService>);
		this.toastService = injector.get<ToastService>(ToastService as Type<ToastService>);
	}

	navigateHome() {
		window.location.href = '/';
	}

	getPath(type: string, item: any) {
		return [
			type,
			item.id,
			item.name
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		];
	}

	getPathAsString(type: string, item: any) {
		return (
			type +
			'/' +
			item.id +
			'/' +
			item.name
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
        .replace('&', 'e')
				.toLowerCase()
		);
	}

  getPathAsString2(type: string, item: any, type2: string, item2: any) {
    return (
      type +
      '/' +
      item.id +
      '/' +
      type2 +
      '/' +
      item2.id +
      '/' +
      item2.name
        .replace(/ /g, '-')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace('&', 'e')
        .toLowerCase()
    );
  }

	getPathTitleAsString(type: string, item: any) {
		return (
			type +
			'/' +
			item.id +
			'/' +
			item.title
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.replace('&', 'e')
				.toLowerCase()
		);
	}
}
